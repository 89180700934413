<!--
 Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
  <tr>
    <td v-if="subject">
      <span :title="subjectTitle">
        <div v-if="subject.description == '*'" style="text-align: center;">{{ subject.description }}</div>
        <div v-else>
        <router-link
          :to="{
            name: 'IBNode',
            params: {
              dsID: subject.datasetID,
              graphURI: subject.graphURI,
              fragment: subject.fragment,
            },
          }"
          >{{ subject.description }}</router-link
        >
      </div>
      </span>
    </td>
    <td v-if="predicate">
      <span :title="predicateTitle">
        <div v-if="predicate.description == '*'" style="text-align: center;">{{ predicate.description }}</div>
        <div v-else>
        <router-link
          v-if="predicate.datasetID"
          :to="{
            name: 'IBNode',
            params: {
              dsID: predicate.datasetID,
              graphURI: predicate.graphURI,
              fragment: predicate.fragment,
            },
          }"
          >{{ predicate.description }}</router-link
        >
        <span v-else>{{ predicate.description }}</span>
        <span v-if="predicateTitle != ''">
          [<em>{{ predicateTitle }}</em
          >]</span
        >
        </div>
      </span>
    </td>
    <td v-if="actualObj">
      <span :title="objectTitle">
        <div v-if="actualObj.description == '*'" style="text-align: center;">{{ actualObj.description }}</div>
        <div v-else>
        <router-link
          v-if="actualObj.datasetID"
          :to="{
            name: 'IBNode',
            params: {
              dsID: actualObj.datasetID,
              graphURI: actualObj.graphURI,
              fragment: actualObj.fragment,
            },
          }"
          >{{ actualObj.description }}</router-link
        >
        <span v-else
          ><input
            type="submit"
            :value="actualObj.description"
            @click="enableEdit($event)"
            @focusout="disableEdit($event, false)"
            @keyup.enter.exact="disableEdit($event, false)"
            @keyup.esc.exact="disableEdit($event, true)"
        /></span>
        </div>
      </span>
    </td>
  </tr>
</template>

<script>
import { useDatasetEditSessions } from "../stores/dsEditSessions";

export default {
  props: {
    dsID: { required: true, type: String },
    fragment: { required: true, type: String },
    subject: { required: true, type: [Object, Boolean] },
    predicate: { required: true, type: [Object, Boolean] },
    object: { required: true, type: [Object, Boolean] },
  },
  setup() {
    const dsEditSessions = useDatasetEditSessions();
    return {
      updateObject: dsEditSessions.updateObject,
      updatedObject: dsEditSessions.updatedObject,
      restoreObject: dsEditSessions.restoreObject,
    };
  },
  computed: {
    actualObj() {
      const updated = this.updatedObject(this);
      return updated !== undefined ? updated : this.object;
    },
    subjectTitle() {
      return typeof this.subject === "object" &&
        this.subject.mainType !== "(nil)"
        ? this.subject.mainType
        : "";
    },
    predicateTitle() {
      return typeof this.predicate === "object" &&
        this.predicate.mainType !== "(nil)"
        ? this.predicate.mainType
        : "";
    },
    objectTitle() {
      return this.actualObj.mainType !== "(nil)" ? this.actualObj.mainType : "";
    },
  },
  data() {
    return {
      objOriginal: undefined,
      objModified: this.updatedObject(this) !== undefined,
    };
  },
  methods: {
    enableEdit(ev) {
      if (this.objOriginal !== undefined) {
        return;
      }
      let input = ev.target;
      this.objOriginal = { ...this.object };
      this.objOriginal.description = input.value;
      input.style.width = input.clientWidth + "px";
      input.type = "text";
      input.focus();
      if (
        this.objOriginal.mainType === "xsd:string" &&
        input.value.length > 0
      ) {
        input.setSelectionRange(input.value.length - 1, input.value.length - 1);
      } else {
        input.setSelectionRange(input.value.length, input.value.length);
      }
      ev.preventDefault();
    },
    disableEdit(ev, discarded) {
      if (this.objOriginal === undefined) {
        return;
      }
      const objOriginal = this.objOriginal;
      this.objOriginal = undefined;
      let input = ev.target;
      input.style.width = null;
      input.type = "submit";
      if (discarded) {
        input.value = objOriginal.description;
      } else if (input.value !== objOriginal.description) {
        const objUpdated = { ...this.object };
        objUpdated.description = input.value;
        this.updateObject(this, objUpdated);
        if (!this.objModified) {
          this.objModified = true;
        } else if (
          this.objModified &&
          objUpdated.description === this.object.description
        ) {
          this.objModified = false;
          this.restoreObject(this);
        }
      }
      ev.preventDefault();
    },
  },
};
</script>

<style scoped>
input {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  padding: 0;
  border: none;
  border-bottom: 1px dashed #ccc;
  line-height: 1.2;
  background: transparent;
}
</style>