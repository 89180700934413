<!--
 Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

 <template>
  <ib-node-details
    v-if="dsID"
    :commitID="commitID"
    :dsID="dsID"
    :graphURI="graphURI"
    :fragment="fragment"
  />
</template>

 <script>
import IBNodeDetails from "../components/IBNodeDetails.vue";
import { useRecentStore } from "../stores/recent";

export default {
  name: "IBNode",
  components: {
    ibNodeDetails: IBNodeDetails,
  },
  setup() {
    const recentStore = useRecentStore();
    return { recentStore };
  },
  props: {
    commitID: { required: false, type: String },
    dsID: { required: false, type: String },
    graphURI: { required: false, type: String },
    fragment: { required: false, type: String },
  },
  beforeMount() {
    this.recentRedir();
  },
  beforeUpdate() {
    this.recentRedir();
  },
  methods: {
    recentRedir() {
      const recentIBNodeInfo = this.recentStore.recentIBNodeInfo;
      if (this.$route.params.dsID === "") {
        this.$router.replace({ name: "SSTRepository" });
      } else if (
        recentIBNodeInfo === undefined ||
        recentIBNodeInfo !==
          {
            dsID: this.$route.params.dsID,
            graphURI: this.$route.params.graphURI,
            fragment: this.$route.params.fragment,
          }
      ) {
        this.recentStore.recentIBNodeInfo = {
          dsID: this.$route.params.dsID,
          graphURI: this.$route.params.graphURI,
          fragment: this.$route.params.fragment,
        };
      }
    },
  },
};
</script>

 <style>
</style>