<!--
 Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
  <table class="details">
    <tbody>
      <tr>
        <td class="name">Dataset:</td>
        <td>
          <router-link
            :to="{
              name: defaultGraph ? 'SSTDataset' : 'SSTDatasetGraph',
              params: defaultGraph
                ? { dsID: this.dsID }
                : { dsID: this.dsID, graphURI: this.graphURI },
            }"
            >{{ dsID }}</router-link
          >
        </td>
      </tr>
      <tr>
        <td class="name">Named Graph:</td>
        <td>{{ graphURI }}</td>
      </tr>
      <tr>
        <td class="name">Fragment:</td>
        <td>{{ ':'+fragment }}</td>
      </tr>
      <tr>
        <td class="name">Main Type:</td>
        <td>{{ mainType }}</td>
      </tr>
    </tbody>
  </table>
  <triple-list
    v-if="triples !== null && triples.length != 0"
    :dsID="dsID"
    :fragment="fragment"
    :currentID="graphURI + fragment"
    :triples="triples"
  />
  <input class="fake-input" type="text" autofocus readonly />
</template>

<script>
import TripleList from "./TripleList.vue";
import { useDatasetEditSessions } from "../stores/dsEditSessions";
import { useGlobalInfo } from "../stores/globalInfo";
// import sortBy from "lodash.sortby";
export default {
  components: {
    TripleList,
  },
  props: {
    commitID: { required: false, type: String },
    dsID: { required: true, type: String },
    graphURI: { required: true, type: String },
    fragment: { required: true, type: String },
  },
  setup() {
    const globalInfo = useGlobalInfo();
    const dsEditSessions = useDatasetEditSessions();
    return {
      globalInfo,
      dsEditSessions: dsEditSessions.sessions,
      dsEditPendingSessions: dsEditSessions.pendingSessions,
      removePendingSession: dsEditSessions.removePendingSession,
      dsServiceSessions: dsEditSessions.serviceSessions,
    };
  },
  computed: {
    defaultGraph() {
      return "urn:uuid:" + this.dsID + "#" === this.graphURI;
    },
  },
  data() {
    return {
      mainType: null,
      triples: null,
      sessionDsID: this.dsID,
    };
  },
  created() {
    this.$watch(
      () => [this.commitID, this.dsID, this.graphURI, this.fragment],
      () => this.fetchData(),
      { immediate: true }
    );
  },
  beforeUpdate() {
    this.saveChangesToSession();
    this.sessionDsID = this.dsID;
  },
  beforeUnmount() {
    this.saveChangesToSession();
  },
  methods: {
    fetchData() {
      const sessionID = this.dsServiceSessions.get(this.dsID);
      this.axios
        .get(
          "/dataset/" +
            this.dsID +
            (sessionID !== undefined && this.commitID === undefined
              ? "/session/" + sessionID
              : this.commitID !== undefined
              ? "/commit/" + this.commitID
              : "") +
            "/graph/" +
            encodeURIComponent(encodeURIComponent(this.graphURI)) + // Double escaping
            "/node/" +
            encodeURIComponent(
              this.fragment === "." ? "%2e" : encodeURIComponent(this.fragment)
            ) // Double escaping
        )
        .then((response) => {
          console.log(response)
          this.mainType = response.data.mainType;
          // this.triples = sortBy(response.data.triples, [
          //   (o) => o.subject.description,
          //   (o) => o.predicate.description,
          // ]);
          this.triples = response.data.triples
          this.globalInfo.updateLoggedInUser(response.data);
        });
    },
    saveChangesToSession() {
      if (this.commitID !== undefined) {
        return;
      }
      const dsID = this.sessionDsID;
      const session = this.dsEditSessions.get(dsID);
      if (session === undefined || this.dsEditPendingSessions.has(dsID)) {
        return;
      }
      this.dsEditPendingSessions.set(dsID, true);
      const sessionID = this.dsServiceSessions.get(this.dsID);
      let sessionGetter =
        sessionID !== undefined
          ? (sessionGetter = new Promise((resolve) => resolve(sessionID)))
          : (sessionGetter = this.startSession());
      const that = this;
      sessionGetter.then((sessionID) => {
        let seq = undefined;
        for (const [fragment, triples] of session.entries()) {
          let r = Array.from(triples, ([tripleKey, updObj]) => {
            var r = {
              match: JSON.parse(tripleKey),
            };
            r["object"] = updObj;
            return r;
          });
          let p = () => {
            return this.axios({
              method: "patch",
              url:
                "/dataset/" +
                dsID +
                "/session/" +
                sessionID +
                "/graph/" +
                encodeURIComponent(encodeURIComponent(this.graphURI)) + // Double escaping
                "/node/" +
                encodeURIComponent(
                  fragment === "." ? "%2e" : encodeURIComponent(fragment)
                ), // Double escaping
              data: {
                replacements: r,
              },
              validateStatus: function (status) {
                return status == 204;
              },
            });
          };
          if (seq === undefined) {
            seq = p();
          } else {
            seq = seq.then(() => {
              return p();
            });
          }
        }
        if (seq !== undefined) {
          seq = seq.then(() => {
            that.removePendingSession(dsID);
            that.fetchData();
          });
        }
      });
    },
    startSession() {
      const that = this;
      return new Promise((resolve) => {
        that
          .axios({
            method: "post",
            url: "/dataset/" + this.dsID + "/session",
            data: {
              branch: "master",
            },
            maxRedirects: 0,
            validateStatus: function (status) {
              return status == 201;
            },
          })
          .then((response) => {
            const sessionURL = response.headers["location"];
            const sessionID = sessionURL.substring(
              sessionURL.lastIndexOf("/") + 1
            );
            that.dsServiceSessions.set(that.dsID, sessionID);
            resolve(sessionID);
          });
      });
    },
  },
};
</script>

<style>
</style>