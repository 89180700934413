<!--
 Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
    <div class="table-container">
    <h3 class="subheader">Triples ({{ Triples.length }}) 
      <!-- | <span @click="this.export()" class="link">Export</span> -->
    </h3>
    <table class="list">
      <thead>
        <tr>
          <th>Subject</th>
          <th>Predicate</th>
          <th>Object</th>
        </tr>
      </thead>
      <tbody>
        <triple-item
          v-for="(item, i) in Triples"
          :key="i"
          :dsID="dsID"
          :fragment="fragment"
          :subject="item.subject"
          :predicate="item.predicate"
          :object="item.object"
        />

      </tbody>
    </table>
  </div>
  <p />
</template>

<script>
import TripleItem from "./TripleItem.vue";
export default {
  components: {
    TripleItem,
  },
  props: {
    dsID: { required: true, type: String },
    fragment: { required: true, type: String },
    currentID: { required: true, type: String },
    triples: { required: true, type: Object },
  },
  computed: {
    Triples() {
      var ft = [];
      for (const t of this.triples) {
        if(t.subject.description.substring(0,1)==":"){
          if(!t.subject.isPunning){
            t.subject.description = t.subject.description + " (" + t.subject.mainType +")";
          }else{
            t.subject.description = t.subject.description + " <" + t.subject.mainType +">";
          }
        }
        if(t.object.description.substring(0,1)==":"){
          if(!t.object.isPunning){
            t.object.description = t.object.description + " (" + t.object.mainType +")";
          }else{
            t.object.description = t.object.description + " <" + t.object.mainType +">";
          }
        }
        if(t.predicate.description.substring(0,1)==":"){
          if(!t.predicate.isPunning){
            t.predicate.description = t.predicate.description + " (" + t.predicate.mainType +")";
          }else{
            t.predicate.description = t.predicate.description + " <" + t.predicate.mainType +">";
          }
        }
        if (t.subject.graphURI + t.subject.fragment === this.currentID) {
          t.subject.description = "*"
          ft.push(t);
        }
        if (t.object.graphURI + t.object.fragment === this.currentID) {
          t.object.description = "*"
          ft.push(t);
        }
        if (t.predicate.graphURI + t.predicate.fragment === this.currentID) {
          t.predicate.description = "*"
          ft.push(t);
        }
      }
      return ft;
    },
    // subjectTriplexes() {
    //   var ft = [];
    //   for (const t of this.triples) {
    //     if (t.subject.graphURI + t.subject.fragment === this.currentID) {
    //       ft.push(t);
    //     }
    //   }
    //   return ft;
    // },
    // objectTriplexes() {
    //   var it = [];
    //   for (const t of this.triples) {
    //     if (t.object.graphURI + t.object.fragment === this.currentID) {
    //       it.push(t);
    //     }
    //   }
    //   return it;
    // },
    // predicateTriplexes() {
    //   var it = [];
    //   for (const t of this.triples) {
    //     if (t.predicate.graphURI + t.predicate.fragment === this.currentID) {
    //       it.push(t);
    //     }
    //   }
    //   return it;
    // },
  },
};
</script>

<style scoped>
.current-node {
  font-style: oblique;
}
</style>